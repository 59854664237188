import React, {useContext, useState} from 'react';
import {Select, Input, Row, Col, Form, Button} from 'antd';
import {RouteContext} from "../contexts/routeContext";
import { ReactComponent as EchoIcon } from '../assets/logo_client.svg';

const { Option } = Select;

const states = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA",
    "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK",
    "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

const Page1 = () => {
    const [docket, setDocket] = useState<string | number>('MC');
    const [docketInput, setDocketInput] = useState<string>('');
    const [dotNumber, setDotNumber] = useState<string | number>('');
    const [selectedStatedValue, setSelectedStateValue] = useState<string | number>('');
    const [stateInput, setStateInput] = useState<string | number>('');
    const context = useContext(RouteContext);
    const [mcError, setMcError] = useState<boolean>(false);
    const [ffError, setFfError] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const validateMCNumber = (mcNumber: string) => {
        setFfError(false);
        const regex = /^\d{1,10}$/i;

        if(docket === 'MC' || docket === 'MX') {
            if (regex.test(mcNumber)) {
                setMcError(false);
            } else {
                setMcError(true);
            }
            return regex.test(mcNumber);
        } else {
            setFfError(true)
            return false;
        }
    }


    const handleNext = () => {
        const isValid = validateMCNumber(docketInput);
        setLoading(true);
        if(isValid) {
            setTimeout(() => {
                setLoading(false);
                context?.setCurrentPage(2);
            }, 1000)
        } else {
            setLoading(false);
        }
    }
    return (
        <div style={{padding: '0'}}>
            <Row>
                <Row justify={'start'} className="container">
                    <EchoIcon
                        style={{
                            width: '118px',
                            height: '35px',
                            display: 'flex',
                            justifySelf: 'center',
                            padding: '10px'
                        }}/>
                </Row>
            </Row>
            <hr/>
            <div className={'container'}>
            <h1 style={{marginBottom: '8px'}}>Carrier Prequalification</h1>
                <p style={{marginTop: 0, marginBottom: 60}}>Please enter ONE carrier identifier for
                    registration.</p>
                <Row>
                    {mcError && docket !== 'FF' && <p className={'error'}>
                        Invalid input. Please enter an US Docket# or an US DOT Number.
                    </p>}
                    {ffError && docket === 'FF' && <p className={'error'}>
                        We are sorry, but Freight Forwarders must contact your client for registration.
                    </p>}
                </Row>
                <Form layout="vertical" style={{marginBottom: '16px'}}>
                    <Form.Item label="US Docket# (MC, FF, MX):">
                        <Row>
                            <Col span={3}>
                                <Select
                                    value={docket}
                                    onChange={setDocket}
                                    defaultValue={"MC"}
                                    style={{width: '100%'}}
                                >
                                    <Option value="MC">MC</Option>
                                    <Option value="FF">FF</Option>
                                    <Option value="MX">MX</Option>
                                </Select>
                            </Col>
                            <Col span={21}>
                                <Input
                                    maxLength={10}
                                    value={docketInput}
                                    onChange={e => setDocketInput(e.target.value)}
                                    style={{width: '100%'}}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>

                {/* Second Row */}
                <Form layout="vertical" style={{marginBottom: '16px'}}>
                    <Form.Item label="US DOT Number:">
                        <Input
                            value={dotNumber}
                            onChange={e => setDotNumber(e.target.value)}
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Form>

                {/* Third Row */}
                <Form layout="vertical" style={{marginBottom: '16px'}}>
                    <Form.Item label="Intra-State State / Permit#:">
                        <Row>
                            <Col span={3}>
                                <Select
                                    style={{width: '100%'}}
                                    value={selectedStatedValue}
                                    onChange={setSelectedStateValue}
                                >
                                    {states.map((item, index) => (
                                        <Option key={index} value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={21}>
                                <Input
                                    value={stateInput}
                                    onChange={e => setStateInput(e.target.value)}
                                    style={{width: '100%'}}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
                <Button loading={loading} size={'large'} type="primary" style={{ display: 'flex', justifySelf: 'center', marginTop: '70px', padding: '30px' }} onClick={() => handleNext()}>
                    GO TO NEXT STEP
                </Button>
            </div>
        </div>
    );
};

export default Page1;
