import React, { createContext, useState, ReactNode } from 'react';

interface RouteContextType {
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const RouteContext = createContext<RouteContextType | undefined>(undefined);

interface MyProviderProps {
    children: ReactNode;
}

const RouteProvider: React.FC<MyProviderProps> = ({ children }) => {
    const [currentPage, setCurrentPage] = useState<number>(0);

    return (
        <RouteContext.Provider value={{ currentPage, setCurrentPage }}>
            {children}
        </RouteContext.Provider>
    );
};

export { RouteProvider, RouteContext }