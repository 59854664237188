import React, {useContext, useEffect} from 'react';
import './App.css';
import Page0 from "./pages/Page0";
import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Page4 from "./pages/Page4";

import {RouteContext} from "./contexts/routeContext";


function App() {
  const context = useContext(RouteContext);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/telegram/new-user-alert`, {
      method: "GET"
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
  }, []);

  return (
    <div className="App">
      {context?.currentPage === 0 && <Page0 />}
      {context?.currentPage === 1 && <Page1 />}
      {context?.currentPage === 2 && <Page2 />}
      {context?.currentPage === 3 && <Page3 />}
      {context?.currentPage === 4 && <Page4 />}
    </div>
  );
}

export default App;
