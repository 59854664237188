import {useEffect} from "react";

const Page4 = () => {
    useEffect(() => {
        window.location.href = "https://ryantranscarriers.rmissecure.com/_c/std/reg/DOTLookupStdV3.aspx";
    }, []);

    return <div>
    </div>
}

export default Page4;