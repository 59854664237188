import {Button, Row} from "antd";
import React, {useContext, useState} from "react";
import {RouteContext} from "../contexts/routeContext";
import { ReactComponent as EchoIcon } from '../assets/logo_client.svg';

const Page0 = () => {
    const context = useContext(RouteContext);
    const [loading, setLoading] = useState(false);

    const handleNext = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            context?.setCurrentPage(1);
        }, 1000)
    }

    return <>
        <div style={{minHeight: 'calc(-106px + 100vh)'}}>
            <Row>
                <Row justify={'start'} className="container">
                    <EchoIcon
                         style={{
                             width: '118px',
                             height: '35px',
                             display: 'flex',
                             justifySelf: 'center',
                             padding: '10px'
                         }}/>
                </Row>
            </Row>
            <hr style={{marginBottom: '30px'}}/>
            <div className={"container"}>
        <span
            id="ctl00_ContentPlaceHolderMain_lblPageRequirements"><div id="content-message"> <p>
            Thank you for your interest in becoming a Ryan Transportation carrier. The registration process should take about 10 minutes to complete. If you have any questions, please call our carrier support team at (888) 925-1931.
Please follow the prompts to fill out or provide the following information:
        </p></div><div
            id="Div3">
            <ol type="1" className="outer-list"> <li>Carrier Profile</li><li>Carrier Agreement</li><li>Insurance Broker Information or Sample Certificate</li></ol>
            <p>
                As a Ryan Transportation carrier, you have access to these exclusive services from our family of brands:
            </p>
            <ul><li>Same-day funding on freight invoices</li><li>Competitive discounts on fuel, maintenance and retread tires</li><li>Customizable trucking software</li><li>Free broker credit data</li><li>Free access to our load board</li><li>Additional savings when you bundle fuel and factoring</li></ul>
            <p>
                Thank you. We look forward to working with you!
            </p>
        </div></span>
                <Row justify={'center'}>
                    <Button loading={loading} size={'large'} type="primary"
                            style={{display: 'flex', justifySelf: 'center', marginTop: '70px', padding: '30px'}}
                            onClick={() => handleNext()}>
                        GO TO NEXT STEP
                    </Button>
                </Row>
            </div>
        </div>
        <Row style={{backgroundColor: '#1a1a1a', padding: '15px', color: 'white'}}>
            <div className={"container"}>
                <div className="container footer-container"><span id="ctl00_UCFooter1_lblFooterText" style={{ color: "#8f8f8f" }}>For technical questions regarding this website, contact RMIS at 888-643-8174 (9am- 8pm EST Mon-Fri). For registration or general questions or after hours, contact Ryan Transportation at 800-383-5231.<br/></span>
                    <div>
                        <label id="ctl00_UCFooter1_lblPrivacyPolicy"><a
                            href="https://truckstop.com/privacy-policy/" target="_blank">RMIS Privacy
                            Policy</a></label><label id="ctl00_UCFooter1_lblDoNotSell">&nbsp;|&nbsp;
                        <a href="https://rmis.com/dont-sell-my-personal-information/" target="_blank">Do Not Sell My
                            Personal Information</a></label>
                    </div>
                    <div style={{
                        backgroundColor: '#2ecc71',
                        borderRadius: '99px',
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginRight: '5px',
                    }} className="color-rmis-status-dot rmis-status-page none"></div>
                    <label className="rmis-status rmis-status-page">RMIS Status - All Systems Operational</label>
                    <div>
                        <label id="ctl00_UCFooter1_lblMaintenanceMessage" className="text-danger"></label>
                    </div>
                    <div id="ctl00_UCFooter1_chatSection" className="chat hidden-sm hidden-md hidden-lg">
                        <div id="ctl00_UCFooter1_transchat">
                            <div id="cimJck" style={{zIndex: 100, position: 'fixed'}}></div>
                            <div id="scmJck" style={{display: 'inline'}} className="AfterHoursChat">
                                <div className="psmtc_mJck"
                                     style={{zIndex: 2147483647, position: 'fixed', bottom: '20px', right: '20px'}}>
                                </div>
                                <div className="psmw_mJck" style={{zIndex: 2147483647, display: 'none'}}></div>
                                <style id="psst_mJck"></style>
                            </div>
                            <div id="sdmJck" style={{display: 'none'}}></div>
                            <noscript>
                                <div style={{display: 'inline'}}><a href="#">Customer Support</a></div>
                            </noscript>
                        </div>
                    </div>
                </div>
            </div>
            <img src="/chat_button.png" alt="" style={{ backgroundColor: "white", borderRadius: '20px', width:"119px", height: '56px' }}/>
        </Row>
    </>
};

export default Page0;

